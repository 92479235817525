import { createTheme } from '@mui/material/styles';

export const Theme = createTheme({
    palette: {
        neutral: {
            main: '#64748B',
            contrastText: '#fff',
        },
        background: {
            // default: "#e4f0e2",
        },
        common: {
            grey: '#efefef',
            signatureBlue: '#a1d6f1',
        },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Where we can add styles to change the color of the navbar
                    backgroundColor: '#f0f8fd',
                    color: '#000',
                },
            },
   
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    //expand the width to utilise the full screen width no matter the viewport size
                    '@media (min-width: 1536px)': {
                        maxWidth: '100%',
                        paddingLeft: '0px',
                        paddingRight: '12px',
                    },
                    '@media (min-width: 600px)': {
                        paddingLeft: '0px',
                        paddingRight: '12px',
                    },
                },
            }
        },
    },
});
