import { AppBar, Box, Button, Toolbar } from "@mui/material";
import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { deleteUserInState } from "../redux/actions";
import API from "../../common/axios/api";

const Header = (props) => {
  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const logout = () => {
    Auth.signOut({ global: true })

      .then(() => {
        dispatch(deleteUserInState());

        document.location.href = "/";
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const [apiResponse, setApiResponse] = useState("");

  useEffect(() => {
    async function fetchData() {
      if (user !== null) {
        let shipCodeBaq = new API("SIG_API_customer_logo");

        try {
          const response = await shipCodeBaq.get();

          if (response.data != null) {
            setApiResponse(await response.data);
          } else {
            console.log(apiResponse);

            setApiResponse(null);
          }
        } catch (err) {
          console.log(err);
        }
      } else {
        setApiResponse("");
      }
    }

    fetchData();

    // console.log(apiResponse)
  }, [user]);

  return (
    <Box sx={{ flexGrow: 1}}>
      <AppBar position="fixed"  sx={{ height: user === null ? "50px" : "64px"  }}>
        <Toolbar>
          {/* If user is authed make link send them home, otherwise back to login */}

          {user === null ? (
            <Box
              sx={{
                position: "fixed",
                
                top: 0,

                left: 15,

                flexGrow: 1,

                textDecoration: "none",

                color: "inherit",
              }}
              component={Link}
              to="/"
            >
              {/* BRAND LOGO */}

              <img
                className="img-fluid"
                src={`${process.env.REACT_APP_URL}assets/sigLogo.png`}
                alt="logo"
                width="120vh"
              />
            </Box>
          ) : (
            <Box
              sx={{
                position: "fixed",

                top: 0,

                left: 0,

                flexGrow: 1,

                textDecoration: "none",

                color: "inherit",
              }}
              component={Link}
              to="/"
            >
              {/* BRAND LOGO */}

              <img
                className="img-fluid"
                src={`${process.env.REACT_APP_URL}assets/sigLogo.png`}
                alt="logo"
                width="150px"
              />
            </Box>
          )}

          {apiResponse && (
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* Clients(MOLSON) LOGO */}

              <img
                className="img-fluid"
                src={`data:image/jpeg;base64,${apiResponse}`}
                alt="logo"
                max-width="400px"
                height="70px"
                object-fit="cover"
              />
            </Box>
          )}

          {/* Simple Auth Check Show a user icon if they are logged in */}

          {user && Object.keys(user).length !== 0 && (
            <div>
              <Box sx={{ position: "absolute", top: "10px", right: "25px" }}>
                <Button onClick={logout} color="inherit">
                  Log out
                </Button>
              </Box>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;