import axios from 'axios';
import Cookies from 'js-cookie';
// Where we configure the axios instance

let axiosConfig = {};

// If we are in dev use this config
if (
    !process.env.REACT_APP_ENVIRONMENT ||
    process.env.REACT_APP_ENVIRONMENT === 'development'
) {
    axiosConfig = {
        baseURL: process.env.REACT_APP_API_BASE_URL,
        headers: {
            'x-api-key': process.env.REACT_APP_X_API_KEY,
        },
        auth: {
            username: 'manager',
            password: 'manager',
        },
    };
} else {
    axiosConfig = {
        baseURL: process.env.REACT_APP_API_BASE_URL,
    };
}

// Create the axios instance
export const apiInstance = axios.create(axiosConfig);

apiInstance.defaults.headers.post['Content-Type'] = 'application/json';

// For debugging requests and responses
// Add a request interceptor
apiInstance.interceptors.request.use(
    (config) => {
        // Do something before request is sent
        // Get the idToken from the cookies
        let cookies = Cookies.get();
        let idToken = null;
        for (var prop in cookies) {
            if (prop.includes('idToken')) {
                idToken = cookies[prop];
            }
        }

        // IF in production mode set the AUTH header to
        if (
            !process.env.REACT_APP_ENVIRONMENT ||
            process.env.REACT_APP_ENVIRONMENT === 'production' ||
            process.env.REACT_APP_ENVIRONMENT === 'qa'
        ) {
            config.headers.Authorization = idToken;
        }

        console.log('Axios preflight request: ', config);

        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
apiInstance.interceptors.response.use(
    (response) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        console.log('Axios response: ', response);

        return response;
    },
    function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
    }
);
