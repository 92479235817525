// Configuring the Cognito Pool
import { Auth } from "aws-amplify";
// import Amplify, { Auth } from 'aws-amplify';

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import { store } from "./common/redux/store";
import reportWebVitals from "./reportWebVitals";

// Refer to https://docs.amplify.aws/lib/auth/start/q/platform/js/#re-use-existing-authentication-resource
// For an explaination about the authentication config
let cookieStorage = {};
console.log(process.env);
if (
  !process.env.REACT_APP_ENVIRONMENT ||
  process.env.REACT_APP_ENVIRONMENT === "development"
) {
  // If Dev store the cookie in localhost in an unsecure manner
  cookieStorage = {
    domain: process.env.REACT_APP_COGNITO_COOKIE_STORAGE_URL,
    secure: false,
    sameSite: "strict",
    expires: 7,
  };
} else {
  cookieStorage = {
    domain: process.env.REACT_APP_COGNITO_COOKIE_STORAGE_URL,
    secure: true,
    path: "/",
    expires: 7,
    sameSite: "strict",
  };

  // TODO:
  // Making all console logs empty
  // console.log = function () {};
}

// TODO: Remove This
if (
  !process.env.REACT_APP_ENVIRONMENT ||
  process.env.REACT_APP_ENVIRONMENT === "production"
) {
  console.log("I am in prod");
}

if (
  !process.env.REACT_APP_ENVIRONMENT ||
  process.env.REACT_APP_ENVIRONMENT === "qa"
) {
  console.log("I am in qa");
}

// Where we configure AWS COGNITO pool options
Auth.configure({
  region: process.env.REACT_APP_COGNITO_REGION,
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
  cookieStorage: cookieStorage,
  oauth: {
    domain: process.env.REACT_APP_COGNITO_DOMAIN,
    scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
    redirectSignIn: process.env.REACT_APP_URL,
    redirectSignOut: process.env.REACT_APP_URL,
    responseType: "token", // or 'token', note that REFRESH token will only be generated when the responseType is code
  },
});
async function checkUserGroups() {
  // const user =  await Auth.currentAuthenticatedUser();

  // user.signInUserSession.accessToken.payload["cognito:groups"]
  // console.log(user.signInUserSession.accessToken.payload['groups']);
  if (process.env.REACT_APP_ENVIRONMENT === "qa") {
    // User belongs to the specific group
    // Redirect the user to the desired page
    const user = await Auth.currentAuthenticatedUser();
    if (
      user.signInUserSession.accessToken.payload["cognito:groups"].includes(
        "Molson.Coors"
      )
    ) {
      window.location.href = "https://molsoncoors.signaturegraphicsinc.com/";
    }
  }
}
checkUserGroups();

console.log(process.env.REACT_APP_COGNITO_DOMAIN);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
