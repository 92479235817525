import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import {
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
  rgbToHex,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import store, { set } from "store";
import update from "store/plugins/update";
import * as yup from "yup";
import API from "../../../common/axios/api";
// import { result } from "lodash";

// Used for placing the order
const todayYup = new Date();
todayYup.setHours(0, 0, 0, 0);

// Validation Schema for order information
const validationSchema = yup.object({
  // lastname: yup.string().required('Required'),
  // firstname: yup.string().required('Required'),
  poNumber: yup.string().required("Required"),
  shipDate: yup
    .date()
    .typeError("Please Enter a valid date!")
    .required("Required")
    .min(todayYup, "Date cannot be in the past!"),
  shipOption: yup.string().required("Required"),
  comments: yup.string(),
});

const OrderInformation = ({ orderComplete }) => {
  const [shipCodes, setShipCodes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ShipDate_c, setShipDate_c] = useState(false);
  const [ShipComment_c, setShipComment_c] = useState(false);
  const [OrdComment_c, setOrdComment_c] = useState("");
  const [ShipText_c, setShipText_c] = useState("");
  // const [shipCountry, setShipCountry] = useState([]);
  // const [shipCountryCodes, setShipCountryCodes] = useState([]);

  // Add the update plugin to the localstorage helper
  store.addPlugin(update);
  const user = useSelector((state) => state.user);

  // Used for placing the order
  const today = new Date();

  const formik = useFormik({
    initialValues: {
      // lastname: '',
      // firstname: '',
      poNumber: "",
      shipDate: null,
      shipOption: "",
      shipCountryCode: "",
      billCC: false,
      CCName: "",
      CCPhoneNum: "",
      comments: "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      setLoading(true);
      // Where I make the requests to epicore to place the order
      const cart = store.get("cart");
      const bill = store.get("bill");
      const ship = store.get("ship");

      // console.log('Items', store.get('cart'));
      // console.log('Billing Address', store.get('bill'));
      // console.log('Shipping Address', store.get('ship'));
      // console.log('Additional', values);

      let requestData = formatOrder(cart, bill, ship, values);
      console.log("RequestData", requestData);

      let placeOrder = new API("PlaceOrder");

      placeOrder
        .post(requestData)
        .then((response) => {
          setLoading(false);
          if (response.status === 200 || response.status === 201) {
            // Redirect user to Order Created Page or Home page with some Modal
            console.log("Response", response);

            // Need to loop through cart and figure out if I need to patch in a personalization
            let orderNum = response.data.OrderNum;
            addPersonalization(cart, orderNum)
              .then((response) => {
                // Delete store when order is created
                store.clearAll();

                // Redirect
                orderComplete(orderNum);
              })
              .catch((error) => {
                console.log("addPersonalization", error);

                alert(
                  `Error with personalization ${JSON.stringify(error.response)}`
                );
              });
          } else {
            // Could Indicate an error, exect 201 on creation
            console.log(response);
            alert(`NO 201 ${JSON.stringify(response)}`);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err.response.data.ErrorMessage);
          alert(
            `Place order error ${JSON.stringify(
              err.response.data.ErrorMessage
            )}`
          );
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    // Check if shipCodes has already been fetched
    if (shipCodes.length === 0) {
      let shipCodeBaq = new API("SIG_API_ShipViaCodes");
      if (user !== null) {
        // Get Shipping Options
        shipCodeBaq
          .get()
          .then((response) => {
            let corpCode =  store.get("corpCode")!== undefined ? store.get("corpCode") : "SIGGRA";
            // let corpCode = "SIGGRA";
            // let corpCode = "IDELEA";
            // let corpCode = "COOBRE";

            let filteredArray = response.data.value.filter((ship) => { return ship.UDCodes_CodeID === corpCode });
            console.log("shipCodes", filteredArray);
            setShipCodes(filteredArray);
            const shipVia = filteredArray.find(
              (ship) => (ship.ShipVia_ShipViaCode === "S100")
            );
            if (shipVia) {
              const shipDate = new Date(shipVia["Calculated_ShipDate"]);
              formik.setFieldValue("shipDate", shipDate);
              // console.log("shipVia", shipVia);
              setShipDate_c(shipVia.UDCodes_WebReqShipDate_c);
              // console.log("ShipDate_c", ShipDate_c);
              setShipComment_c(shipVia.UDCodes_WebShipComment_c);
              // console.log("ShipComment_c", ShipComment_c);
              setOrdComment_c(shipVia.UDCodes_WebOrdComment_c);
              // console.log("OrdComment_c", OrdComment_c);
              setShipText_c(shipVia.UDCodes_WebShipText_c);
              // console.log("UDCodes_WebShipText_c", ShipText_c);
            }
            formik.setFieldValue("shipOption", "S100");
          })
          .catch((err) => console.log(err));
      }
    }
  }, [shipCodes, formik, user]);

  function setShipDate(value) {
    let shipViaCode = value;
    let shipVia = shipCodes.find(
      (ship) => ship.ShipVia_ShipViaCode === shipViaCode
    );
    formik.setFieldValue("shipDate", new Date(shipVia["Calculated_ShipDate"]));
    formik.setFieldValue("shipOption", shipViaCode);
    // console.log("shipVia", shipVia);
    setShipDate_c(shipVia.UDCodes_WebReqShipDate_c);
    // console.log("ShipDate_c", ShipDate_c);
    setShipComment_c(shipVia.UDCodes_WebShipComment_c);
    // console.log("ShipComment_c", ShipComment_c);
    setOrdComment_c(shipVia.UDCodes_WebOrdComment_c);
    // console.log("OrdComment_c", OrdComment_c);
    setShipText_c(shipVia.UDCodes_WebShipText_c);
    // console.log("UDCodes_WebShipText_c", ShipText_c);
  }

  // Function to handle one to many promises for adding personalization to a part
  let addPersonalization = (cart, orderNum) => {
    let patchOrder = new API("Patch Order");

    const promiseArr = [];

    cart.items.forEach((item, index) => {
      if (item.personalization !== undefined) {
        let requestData = {
          Company: "SG100",
          OrderNum: orderNum,
          OrderLine: index + 1,
          OrderRelNum: 1,
          Personalization_Data_c: item.personalization, // Personalization
          RowMod: "U",
        };

        promiseArr.push(
          new Promise((resolve, reject) => {
            patchOrder
              .patchPersonalization(requestData, orderNum, index + 1)
              .then((response) => {
                resolve(response);
              })
              .catch((error) => {
                reject(error);
              });
          })
        );
      }
    });

    return Promise.all(promiseArr);
  };

  const formatOrder = (cart, bill, ship, order) => {
    // console.log(order);
    // Convert Ship Date to Midnight
    let requestDate = order.shipDate;
    requestDate.setHours(0, 0, 0, 0);

    // Let's prepare the order items first
    let OrderDtls = [];
    cart.items.forEach((item) => {
      // console.log(item);

      // If the part has a personalization add it
      let personalization = "";
      if (item.personalization !== undefined) {
        personalization = item.personalization;
      }

      let dtl = {
        OpenLine: true,
        Company: "SG100",
        OrderNum: 0,
        OrderLine: 0,
        LineType: "PART",
        PartNum: item.partNum,
        RevisionNum: (item.PartRev_RevisionNum !== null) ? item.PartRev_RevisionNum : "",
        UnitPrice: item.price,
        DocDspUnitPrice: item.price,
        DocUnitPrice: item.price,
        OrderQty: item.quantity,
        SellingQuantity: item.quantity,
        RowMod: "I",
        OrderComment: personalization,
        Personalization_Web_c: personalization,
        OrderRels: [
          {
            Company: "SG100",
            OrderNum: 0,
            OrderLine: 0,
            OrderRelNum: 0,
            LineType: "PART",
            ReqDate: requestDate,
            OpenRelease: true,
            FirmRelease: true,
            Make: false,
            PartNum: item.partNum,
            RevisionNum: (item.PartRev_RevisionNum !== null) ? item.PartRev_RevisionNum : "",
            RowMod: "I",
            Personalization_Data_c: personalization,
            Personalization_File_c: "",
          },
        ],
      };

      // Add the Part to the Order Details list
      OrderDtls.push(dtl);
    });

    /*----------------------------------------------------------------
            Creating the head of the request now
        */

    // Determing whether we should use an OTS (one time shipping) or just a regular ship num
    let shipOrOTS = {};
    let shipAddr = ship.shipping;

    console.log("shipp address", shipAddr);
    if (shipAddr.OTS !== undefined && shipAddr.OTS === true) {
      // const MAX_LINE_LENGTH = 14;
      // IF a user wants to save the address we will set the necessary fields here
      let saveAddr = {};
      if (shipAddr.SaveAddress !== undefined && Boolean(shipAddr.SaveAddress)) {
        let addrName = `${shipAddr.Customer_CustID}-${shipAddr.ShipTo_Address1}`;

        // The Save as Name must be 14 char or less
        saveAddr = {
          OTSSaveAs: "T",
          OTSShipToNum: `${addrName.length > 14 ? addrName.substr(0, 14) : addrName
            }`,
        };
      }

      shipOrOTS = {
        UseOTS: true,
        OTSName: shipAddr.ShipTo_Name,
        OTSAddress1: shipAddr.ShipTo_Address1,
        OTSAddress2: shipAddr.ShipTo_Address2,
        OTSAddress3: shipAddr.ShipTo_Address3,
        OTSCity: shipAddr.ShipTo_City,
        OTSState: shipAddr.ShipTo_State,
        OTSZIP: shipAddr.ShipTo_ZIP,
        OTSContact: shipAddr.ShipTo_Attention,
        OTSFaxNum: shipAddr.ShipTo_FaxNum,
        OTSPhoneNum: shipAddr.ShipTo_PhoneNum,
        // OTSCountryNum: formik.values.shipCountryCode,
        OTSCountryNum: shipAddr.ShipTo_CountryNum,

        ...saveAddr,
      };
      console.log("shipOrOTS", shipOrOTS);
    } else {
      shipOrOTS = { ShipToNum: shipAddr.ShipTo_ShipToNum };
    }

    // Order Comments
    console.log(order);
    let comment = order.billCC
      ? `Bill CC - ${order.CCName} ${order.CCPhoneNum} : ${order.comments}`
      : order.comments;

    let request = {
      OpenOrder: true,
      Company: "SG100",
      OrderNum: 0,
      CustNum: bill.billing.Customer_CustNum,
      PONum: order.poNumber,
      OTSCountryNum: formik.values.shipCountryCode,
      EntryPerson: "eCommerce",
      ...shipOrOTS,
      ShipViaCode: order.shipOption,
      OrderComment: comment,
      NeedByDate: requestDate,
      OrderDate: today,
      OHOrderMscs: [
        {
          Company: "SG100",
          OrderNum: 0,
          OrderLine: 0,
          SeqNum: 0,
          MiscCode: "M100",
          MiscAmt: bill.billing.Calculated_ReleaseAmount, // TODO: Get release charge data from custID BAQ
          DocMiscAmt: bill.billing.Calculated_ReleaseAmount,
          FreqCode: "F",
          Quoting: "",
          Linked: false,
          Type: "A",
          DocDspMiscAmt: bill.billing.Calculated_ReleaseAmount,
          DspMiscAmt: bill.billing.Calculated_ReleaseAmount,
          RowMod: "I",
        },
      ],
      OrderDtls: OrderDtls,
    };

    console.log("Request", request);
    return request;
  };

  return (
    <Paper elevation={3} sx={{ p: 1, mt: 3 }}>
      <Grid container>
        {/* Section Title */}
        <Grid item xs={12}>
          <Typography variant="h5" align="center">
            Order Information
          </Typography>
          <Divider />
        </Grid>

        {/* Form */}
        <Grid container item xs={12} mt={1} justifyContent="center">
          <form onSubmit={formik.handleSubmit}>
            {/* PO Number ? Possibly means Purchase Order Number */}
            <Grid item mt={1}>
              <TextField
                sx={{ width: "33ch" }}
                fullWidth
                id="poNumber"
                name="poNumber"
                label="P.O. Number"
                required
                value={formik.values.poNumber}
                onChange={formik.handleChange}
                error={
                  formik.touched.poNumber && Boolean(formik.errors.poNumber)
                }
                helperText={formik.touched.poNumber && formik.errors.poNumber}
              />
            </Grid>

            {/* shipOption */}
            <Grid item mt={1}>
              <TextField
                sx={{ width: "25ch" }}
                fullWidth
                id="shipOption"
                name="shipOption"
                select
                label="Shipping Method"
                required
                value={formik.values.shipOption}
                onChange={(event) => {
                  setShipDate(event.target.value);
                  formik.handleChange(event);
                }}
                error={
                  formik.touched.shipOption && Boolean(formik.errors.shipOption)
                }
                helperText={
                  formik.touched.shipOption && formik.errors.shipOption
                }
              >
                {shipCodes.map((ship) => (
                  <MenuItem
                    key={ship.ShipVia_ShipViaCode}
                    value={ship.ShipVia_ShipViaCode}
                  >
                    {ship.ShipVia_Description}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {ShipDate_c ?
              <Grid item mt={1}>
                <DesktopDatePicker
                  format="MM/dd/yyyy"
                  id="shipDate"
                  name="shipDate"
                  label="Requested Ship Date *"
                  // set the value attribute to the Calculated_ShipDate value whose shipvia_shipviacode matches the shipOption value from the shipcodes array
                  value={formik.values.shipDate}
                  // add one day to the current date and set it's value to minDate attritube
                  minDate={new Date(new Date().setDate(new Date().getDate() + 1))}
                  onChange={(value) => {
                    formik.setFieldValue("shipDate", value);
                  }}
                  error={formik.touched.shipDate && formik.errors.shipDate}
                />

                {formik.touched.shipDate && formik.errors.shipDate && (
                  <div style={{ color: "red" }} className="error">
                    {formik.errors.shipDate}
                  </div>
                )}
              </Grid> : null}

            {ShipComment_c ?
              <Grid item mt={1}>
                <TextField
                  sx={{ width: "33ch" }}
                  fullWidth
                  id="shippingInformation"
                  name="shippingInformation"
                  label="Shipping Information"
                  required
                  value={ShipText_c}
                  // onChange={formik.handleChange}
                  // error={
                  //   formik.touched.shiPpin && Boolean(formik.errors.poNumber)
                  // }
                  // helperText={formik.touched.poNumber && formik.errors.poNumber}
                />
              </Grid> : null}

            {/* Bill CC */}
            <Grid item mt={1}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="billCC"
                      checked={formik.values.billCC}
                      onChange={(e) => {
                        let event = e;
                        event.target.value = e.target.checked;
                        formik.handleChange(event);
                      }}
                    />
                  }
                  label="Bill Credit Card"
                />
              </FormGroup>
            </Grid>

            {formik.values.billCC && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    sx={{ width: "25ch" }}
                    fullWidth
                    id="CCName"
                    name="CCName"
                    label="Contact Name"
                    required
                    value={formik.values.CCName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.CCName && Boolean(formik.errors.CCName)
                    }
                    helperText={formik.touched.CCName && formik.errors.CCName}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={1}
                  mt={-3.5}
                  container
                  alignItems="flex-start"
                >
                  <Grid item>
                    <div style={{ height: 60, width: 60, marginLeft: "50px" }}>
                      <img
                        src={`${process.env.REACT_APP_URL}assets/WarningIcon.png`}
                        alt="Warning Icon"
                        style={{
                          height: "150%",
                          width: "150%",
                          objectFit: "cover",
                          marginLeft: "-15px",
                        }}
                      />
                      <Grid item xs={12}>
                        <Typography
                          variant="caption"
                          sx={{
                            marginTop: "100%",
                            marginLeft: "-200%",
                            color: "red",
                            fontWeight: "bold",
                            fontSize: "1.1rem",
                          }}
                          noWrap
                        >
                          DO NOT enter your credit card information.
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="caption"
                          sx={{
                            position: "relative",
                            top: "-0.4rem",
                            marginLeft: "-140%",
                            color: "red",
                            fontWeight: "bold",
                            fontSize: "1.1rem",
                          }}
                          noWrap
                        >
                          We will contact you for payment.
                        </Typography>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>

                <Grid item xs={false} sm={1} />
                <Grid item xs={12} mt={-1}>
                  <TextField
                    sx={{ width: "25ch" }}
                    fullWidth
                    id="CCPhoneNum"
                    name="CCPhoneNum"
                    label="Phone Number"
                    required
                    value={formik.values.CCPhoneNum}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.CCPhoneNum &&
                      Boolean(formik.errors.CCPhoneNum)
                    }
                    helperText={
                      formik.touched.CCPhoneNum && formik.errors.CCPhoneNum
                    }
                  />
                </Grid>
              </Grid>
            )}
            {/* Shipping_WebOrdComment // color should be rdg(56, 116, 203)*/}
            
            <Grid item  sm={8}>
              <p style={{color: "rgb(56, 116, 203)" }}>{OrdComment_c}</p>
            </Grid>
            {/* Comments */}
            <Grid item mt={1}>
              <TextField
                sx={{ width: "50ch" }}
                fullWidth
                id="comments"
                name="comments"
                label="Comments"
                multiline
                rows={4}
                value={formik.values.comments}
                onChange={formik.handleChange}
                error={
                  formik.touched.comments && Boolean(formik.errors.comments)
                }
                helperText={formik.touched.comments && formik.errors.comments}
              />
            </Grid>

            {/* Place Order & Back Button*/}
            <Grid container justifyContent="space-between" mt={3}>
              <Grid item>
                <Button
                  component={Link}
                  to="/placeorder/address"
                  variant="contained"
                  color="neutral"
                >
                  Back
                </Button>
              </Grid>
              <Grid item mt={1}>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Button color="primary" variant="contained" type="submit">
                    Place Order
                  </Button>
                )}
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default OrderInformation;
